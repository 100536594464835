import React, { useState } from "react";
import { FiMenu, FiArrowRight, FiX, FiChevronDown } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import {
  useMotionValueEvent,
  AnimatePresence,
  useScroll,
  motion,
} from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";

import { ABOUT, ACCUEIL,ATELIER,CONTACT,DIVERS, ECOLE, VIEW_PDF } from '../../routes/routes';
import { useNavigate } from "react-router-dom";

import parcours_managerial from "../../assets/pdf/Parcours_managerial.pdf"
import executive_class from "../../assets/pdf/Executive-Class-EAO-2023.pdf"
import fiche_modalite from "../../assets/pdf/fiche-modalites-2023.pdf"
import eao_charite_quality from "../../assets/pdf/EAO-CHARTE-QUALITE.pdf"
import plaquette_eao from "../../assets/pdf/EAO-Plaquette-2023.pdf"
import executive from "../../assets/pdf/Executive_class.pdf"
import masterclass from "../../assets/pdf/MasterClass.pdf"
import logo from "../../assets/images/home/eao_logo_bg.png"
import fond from "../../assets/background/leadership.jpg"

import "./style.css"
import HomeModal from "../../pages/Home/components/Modal/HomeModal";

import ecole_video from "../../assets/videos/video.mp4"
import VideoT from "../../pages/Home/components/Video_T/VideoT";

import ReactPlayer from 'react-player'

const HomeHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <FlyoutNav />
      <div
        className="relative min-h-screen "
      >
        {/*<div className="absolute inset-0 z-0 bg-gradient-to-b from-neutral-950/90 to-neutral-950/0" />*/}
        <div className='ecole_head'>
        <div className='video_t' style={{width:"100%",height:"130vh",backgroundColor:"black", marginTop:"-5vh",borderRadius:"0px"}}>
          <ReactPlayer url={ecole_video} 
                    playing
                    muted
                    controls={false}
                    width="100%"
                    height="100%"
                    loop
                    className="player-video_temoin"
                />
        </div>
        </div>
        <div className="home_title">
          <h1 className="hidden">Ecole d'Art Oratoire et de <span style={{color:"#FFA500"}}>Leadership</span></h1>
          <p className="hidden">Ecole de renommée internationale, elle a pour objectif de former des leaders et des orateurs d'exception</p>
          <div className=" absolute flex gap-5 " style={{bottom:"-300px"}} >
              <a href="#actualites" className="new_home_button">Actualités</a>
              <button className="new_home_video " onClick={() => setIsOpen(true)} >
                <FontAwesomeIcon icon={faCirclePlay} className="new_home_video_icon" style={{width:"40px",height:"40px"}}/>  

                Visionner
              </button>
              <HomeModal isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
        </div>
        
      </div>
    
    </>
  );
};

export const FlyoutNav = () => {
  const [scrolled, setScrolled] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    setScrolled(latest > 250 ? true : false);
  });

  return (
    <nav
      className={`fixed top-0 z-50 w-full px-6 text-white 
      transition-all duration-300 ease-out lg:px-12
      ${
        scrolled
          ? "bg-neutral-950 py-3 shadow-xl"
          : "bg-neutral-950/0 py-6 shadow-none"
      }`}
    >
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <Logo />
        <div className="hidden gap-6 lg:flex">
          <Links />
        </div>
        <MobileMenu />
      </div>
    </nav>
  );
};

const Logo = ({ color = "white" }) => {
  // Temp logo from https://logoipsum.com/
  const navigate = useNavigate()
  return (
    <div className="flex items-center gap-2">
      <span className="text-2xl font-bold" style={{ color }}>
        <img
            onClick={() => navigate('/')}
            src={logo}
            alt="EAO_Logo"
            className="head__logo"
        />
      </span>

    </div>
  );
};

const Links = () => {
  return (
    <div className="flex items-center gap-6">
      {LINKS.map((l) => (
        <NavLink key={l.text} href={l.href} FlyoutContent={l.component}>
          {l.text}
        </NavLink>
      ))}
    </div>
  );
};

const NavLink = ({ children, href, FlyoutContent }) => {
  const [open, setOpen] = useState(false);

  const showFlyout = FlyoutContent && open;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative w-fit h-fit"
    >
      <a href={href} className="relative text-white">
        {children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-orange-300 transition-transform duration-300 ease-out text-white"
        />
      </a>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-white text-black"
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const CTAs = () => {
  return (
    <div className="flex items-center gap-3">
      <button className="flex items-center gap-2 rounded-lg border-2 border-white px-4 py-2 font-semibold text-white transition-colors hover:bg-white hover:text-black">
        <FaUserCircle />
        <span>Sign in</span>
      </button>
      <button className="rounded-lg border-2 border-orange-300 bg-orange-300 px-4 py-2 font-semibold text-black transition-colors hover:border-orange-600 hover:bg-orange-500 hover:text-white">
        Schedule a Demo
      </button>
    </div>
  );
};

const AboutUsContent = () => {
  return (
    <div className="grid h-fit w-full grid-cols-12 shadow-xl lg:h-72 lg:w-[600px] lg:shadow-none xl:w-[750px]">
      <div className="col-span-12 flex flex-col justify-between bg-neutral-950 p-6 lg:col-span-4">
        <div>
          <h2 className="mb-2 text-xl font-semibold text-white">Acceuil</h2>
          <p className="mb-6 max-w-xs text-sm text-neutral-400">
            Ecole d'Art Oratoire et de Leadership
          </p>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-2 grid-rows-2 gap-3 bg-white p-6 lg:col-span-8">
        <a
          href={ECOLE}
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100"
        >
          <h3 className="mb-1 font-semibold text-orange-400">Ecole</h3>
          <p className="text-xs text-black">
            Nos formations
          </p>
        </a>
        <a
          href={ATELIER}
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100"
        >
          <h3 className="mb-1 font-semibold text-orange-400">Ateliers</h3>
          <p className="text-xs text-black">
            L'ensemble de nos services'
          </p>
        </a>
        <a
          href={ABOUT}
          className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors hover:bg-neutral-100 text-orange-400"
        >
          <h3 className="mb-1 font-semibold">A Propos</h3>
          <p className="text-xs text-black">
            Notre personnel
          </p>
        </a>
        
      </div>
    </div>
  );
};

const PricingContent = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-white p-6 shadow-none lg:w-[250px] lg:shadow-xl">
      <div className="grid grid-cols-2 lg:grid-cols-1">
      
        <div className="mb-6 space-y-3">
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: parcours_managerial}})} className="block text-sm hover:underline cursor-pointer">Parcours Managérial EAO</div>
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: executive_class}})} className="block text-sm hover:underline cursor-pointer">Executive class EAO</div>
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: masterclass}})} className="block text-sm hover:underline cursor-pointer">Master class EAO</div>
        </div>
      </div>

    </div>
  );
};

const CareersContent = () => {
  const navigate = useNavigate();
  return (
    <div className="grid w-full grid-cols-12 shadow-xl lg:w-[750px]">
      <div className="col-span-12 flex flex-col justify-between bg-orange-400 p-6 lg:col-span-4">
        <div className="mb-6">
          <h2 className="mb-2 text-xl font-semibold text-white">Divers</h2>
          <p className="text-sm text-orange-100">
            Ressources
          </p>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-2 gap-3 bg-white p-6 lg:col-span-8 lg:grid-cols-3">
        <div className="space-y-3">
          <h3 className="font-semibold">Business</h3>
          <div onClick={()=> navigate(DIVERS)} className="block text-sm hover:underline cursor-pointer">Livres</div>
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: executive}})} className="block text-sm hover:underline cursor-pointer">Executive Class 2023</div>
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: plaquette_eao}})} className="block text-sm hover:underline cursor-pointer">Plaquette EAO</div>
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: eao_charite_quality}})} className="block text-sm hover:underline cursor-pointer">Charte Qualité EAO</div>
          <div onClick={()=> navigate(VIEW_PDF, {state:{link: fiche_modalite}})} className="block text-sm hover:underline cursor-pointer">Fiche de Modalité EAO</div>
        </div>
      </div>
    </div>
  );
};

const ServiceClient = () => {
  return(
    <div>

    </div>
  )
}

const MobileMenuLink = ({ children, href, FoldContent, setMenuOpen }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="relative text-neutral-950">
      {FoldContent ? (
        <div
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
          onClick={() => setOpen((pv) => !pv)}
        >
          <a
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(false);
            }}
            href={href}
          >
            {children}
          </a>
          <motion.div
            animate={{ rotate: open ? "180deg" : "0deg" }}
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
          >
            <FiChevronDown />
          </motion.div>
        </div>
      ) : (
        <a
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen(false);
          }}
          href="#"
          className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
        >
          <span>{children}</span>
          <FiArrowRight />
        </a>
      )}
      {FoldContent && (
        <motion.div
          initial={false}
          animate={{
            height: open ? "fit-content" : "0px",
            marginBottom: open ? "24px" : "0px",
            marginTop: open ? "12px" : "0px",
          }}
          className="overflow-hidden"
        >
          <FoldContent />
        </motion.div>
      )}
    </div>
  );
};

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="block lg:hidden">
      <button onClick={() => setOpen(true)} className="block text-3xl">
        <FiMenu />
      </button>
      <AnimatePresence>
        {open && (
          <motion.nav
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            exit={{ x: "100vw" }}
            transition={{ duration: 0.15, ease: "easeOut" }}
            className="fixed left-0 top-0 flex h-screen w-full flex-col bg-white"
          >
            <div className="flex items-center justify-between p-6">
              <Logo color="black" />
              <button onClick={() => setOpen(false)}>
                <FiX className="text-3xl text-neutral-950" />
              </button>
            </div>
            <div className="h-screen overflow-y-scroll bg-neutral-100 p-6">
              {LINKS.map((l) => (
                <MobileMenuLink
                  key={l.text}
                  href={l.href}
                  FoldContent={l.component}
                  setMenuOpen={setOpen}
                >
                  {l.text}
                </MobileMenuLink>
              ))}
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HomeHeader;

const LINKS = [
  {
    text: "Accueil",
    href: ACCUEIL,
    component: AboutUsContent,
  },
  {
    text: "Calendriers",
    href: "#",
    component: PricingContent,
  },
  {
    text: "Divers",
    href: "#",
    component: CareersContent,
  },
  {
    text: "Service Client",
    href: CONTACT,
  },
];