import React, { useState,useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import './home.css'
import './countup.css'
import './components/Counter/counter.css'

import "./slide.css"

import { data_home,partenaires,images} from '../../constants/home';

import VideoT from './components/Video_T/VideoT';

import CardActualite from "./components/Card_Actualite.js/CardActualite"
import {motion} from "framer-motion"
import Counter_stats from "./components/Counter/Counter";;

import dr_diallo from "../../assets/images/home/formateurs/Dr_DIALLO.jpg"

import {staff} from "../../constants/staff"

import $ from 'jquery'
import Reveal from "../../components/Reveal/Reveal";

import { actualites_ } from "../../constants/actualites";

import ptit_diallo from "../../assets/images/home/formateurs/little/dr_diallo_blur.png"

import hand_word from "../../assets/images/divers/hand_world_center.jpg"
import useTypingEffect from "../../hook/typing-effect";
import {useTypewriter,Cursor} from "react-simple-typewriter"
import Reveal40 from "../../components/Reveal/Reveal40";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
import HomeModal from "./components/Modal/HomeModal";

import Letre_fondateur from "../../assets/pdf/Lettre-du-Fondateur.pdf"
import { VIEW_PDF } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/test/Footer";

import Card_ from "./components/card_/Card_";
import { Statistiques } from "../../components/New/Statistique";
import { VosLimits } from "../../components/New/VosLimit";
import Actualites from "../../components/New/Actualites";

import classe from "../../assets/images/icons_formations/classe.png"
import institutionnelle from "../../assets/images/icons_formations/empire.png"
import urgence from "../../assets/images/icons_formations/sortie-de-secours.png"
import team_building from "../../assets/images/icons_formations/collaboration.png"
import individuelle from "../../assets/images/icons_formations/direction.png"
import manager from "../../assets/images/icons_formations/management (1).png"
import entreprise from "../../assets/images/icons_formations/entreprise.png"
import masterclass from "../../assets/images/icons_formations/education.png"
import HomeHeader from "../../components/New/HomeHeader";




const Home = () => {

  const [isOpen, setIsOpen] = useState(false);

  

  const navigate = useNavigate()

    const formations = [
      {
        id:1,
        title:"PARCOURS MANAGERIAL ",
        image:manager
      },
      {
        id:2,
        title:"FORMATION ENTREPRISE",
        image:entreprise
      },
      {
        id:3,
        title:"TRAINING D\'URGENCE",
        image:urgence
      },
      {
        id:4,
        title:"COACHING INDIVIDUEL",
        image:individuelle
      },
      {
        id:7,
        title:"FORMATIONS INSTITUTIONNELLES",
        image: institutionnelle
      },
      {
        id:8,
        title:"TEAM BUILDING",
        image:team_building
      },
      {
        id:1,
        title:"EXECUTIVE CLASSE",
        image:classe
      },
      {
        id:1,
        title:"MASTERCLASS",
        image:masterclass
      },
    ]

    const temoignages = [
      {
        external_video:"https://youtu.be/Yk841GOArck",
        titre: "LE POINT invité Dr Cheikh Omar DIALLO"
       
      },
      {
        external_video:"https://youtu.be/f9epjH-L8EY?si=uAotczyRQrpsr0i0",
        titre: "Interview de M. Madieng Top, Directeur Général de l'EAO sur SOIR D'INFO"
       
      },
      {
          external_video:"https://www.youtube.com/watch?v=2O_8Ueho6Qg&ab_channel=ECOLED%27ARTORATOIREDEDAKAR",
          titre: "Témoignage 69ième promotion"
         
      },
      {
        external_video:"https://youtu.be/Yk841GOArck",
        titre:"Direct Un leader qui corrige sa posture Le Président Diomaye à la loupe Décryptage de coachs"
      }
  ]


    useEffect(()=>{

      $(function () {
        $('#section-feature .sf-wrap')
            .hover(function() {
                $(this).addClass('hover');
            }, function() {
                $(this).removeClass('hover');
            })
      });

    })



    const citation = useTypingEffect("LA PAROLE GOUVERNE LE MONDE", 500)
    //const leadership = useTypingEffect("LEADERSHIP", 400)
    

  return (
    <motion.div
      initial={{ opacity : 0 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
    >
      {/*<Navbar />*/}
      
      <motion.div 
        className="home_carousel"
        
        initial={{ opacity : 0 }}
        animate={{ opacity : 1 }}
        exit={{ opacity : 0 }}
      >
        
      

    <HomeHeader />
        {/********************************************************** BODY HOME ***************************************************************** */}
        <Reveal>
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}

            className="section_about"
            
        >
          <div className="section_about_body">
            <div className='section_about_element'>

                <hr className='body_home_title_trait' style={{backgroundColor:"#000"}} />
                <p>{data_home[0].content}</p>
                <button class="body_home_button-86" role="button" onClick={()=>navigate(VIEW_PDF,{state:{link:Letre_fondateur}})}>Voir Plus...</button>
              
            </div>

          </div>
         
        </motion.div>

        </Reveal>
        

        <Statistiques />
        

      <Reveal>
      <motion.div 
            className='section_formation'
            initial={{ opacity : 0 }}
            animate={{ opacity : 1 }}
            exit={{ opacity : 0 }}
            
        >
          <div className="section_card_list">
            
            {formations.map((formation,index) =>{
              return(
                <Card_ title={formation.title} image={formation.image}/>
              )
            })}
             
          </div>
                  

        </motion.div>
      </Reveal>
      

       <VosLimits />

      <Actualites />

      <Reveal>
        <motion.div 
            className='se_decouvrir'
            initial={{ opacity : 0 }}
            animate={{ opacity : 1 }}
            exit={{ opacity : 0 }}
            
        >
          <h1 className="titre_temoigneur">{temoignages[0].titre}</h1>

          <hr />
          
          <div className='video_témoignages'>
              <VideoT link={temoignages[0].external_video} />
          </div>
          
        </motion.div>
      </Reveal>
      {/********************************************* END SE DECOUVRIR A SOI-MEME ***************************************************************** */}

      {/************************************************ PARTENAIRES ****************************************************************************** */}
      <Reveal>
          <motion.div 
              className='new_home_partenaires' 
              style={{marginTop:"20vh"}}
              initial={{ opacity : 0 }}
              animate={{ opacity : 1 }}
              exit={{ opacity : 0 }}
              
          >
          <h1 style={{textAlign:"center",marginBottom:"0vh",fontSize:"50px",textShadow:"1px 2px #f9f9f9",color:"#EB7120"}}>PARTENAIRES</h1>
            <div className="slider">
              <div className="slide_track">
                {partenaires.map((item,index)=>{
                    return(
                      <div className="slide" key={index}>
                        <img src={item.source} alt={item.title} className="img" />
                      </div>
                    )
                })}
                
              </div>
              <div className="cache"></div>
            </div>
          </motion.div>

        </Reveal>
        
      {/*************************************************** END PARTENAIRES ********************************************************************** */}


      
      </motion.div>

      <Footer />
    </motion.div>
    
  )
}

export default Home