
import promotion from "../assets/images/actualites/79promotion.jpeg"
import eurocam from "../assets/images/actualites/eurocam.jpeg"
import woodside from "../assets/images/actualites/woodsde.jpeg"

export const actualites_ = [
  {
    id: 1,
    presentateur: woodside,
    date: "Du 13 nov au 15 nov",
    title:"Prise de Parole en Public",
    description:"Prise de parole en public - Communication efficace",
    resume:["WOODSIDE ENERGY","Prise de parole en public - Communication efficace","Directeurs, Cadres et Agents de maîtrise"],
    images: [
      {
        src:woodside,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
  {
    id: 11,
    presentateur: eurocam,
    date: "Du 11 nov au 13 nov",
    title:"Formation Entreprise - Media Training ",
    description:"Art Oratoire - Media Training - EUROCHAM",
    resume:["MANAGERS - RESSOURCES HUMAINES - MARKETEURS","EUROCHAM","Art Oratoire - Media Training"],
    images: [
      {
        src:eurocam,
        title: "A Title",
        id: 1,
      },
      
    ]

  },
  {
    id: 10,
    presentateur: promotion,
    date: "Jusqu'au 22 Nov 2024",
    title:"Inscription ouverte",
    description:"Leader & Managers - 79ie Promotion",
    resume:["Leadership et Management","79ième Promotion"],
    images: [
      {
        src:promotion,
        title: "A Title",
        id: 1,
      },
      
    ]

  },

    


]